import React from 'react';

import Layout from '../components/layout';
import Checkout from '../components/checkout';

const Order = () => (
  <Layout>
    <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <Checkout />
    <br />
    <br />
  </Layout>
);
export default Order;
